<template>
  <div class="main-layout">
    <Slideout
      menu="#sidebar"
      panel=".main-content"
      padding="225"
      :toggle-selectors="['.navbar-toggler']"
    >
      <Sidebar :user="user" />
      <div class="main-content">
        <navbar :user="user" />
        <div class="content">
          <router-view />
        </div>
      </div>
    </Slideout>
  </div>
</template>

<script>
import Slideout from 'vue-slideout'
import Navbar from './NavBar'
import Sidebar from './Sidebar'
import store from '../../store'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Slideout,
    Sidebar
  },
  computed: {
    isIos () {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    }
  },
  created () {
    if (this.isIos) {
      this.$nextTick(() => {
        document.querySelector('.main-layout>div:first-child').classList.add('ios-page')
      })
    }
    this.user = store.getters.loggedInUser
  }
}
</script>
