<template>
  <div id="sidebar">
    <initials-circle
      :first="firstInitial"
      :second="secondInitial"
    />
    <div class="hr" />
    <ul class="nav flex-column text-left px-3">
      <li class="nav-item">
        <router-link
          v-if="user.isAccountActive"
          :to="{ name: 'MyAccount' }"
          class="nav-link"
          active-class="active"
          exact
          @click.native="closeSideBar"
        >
          {{ $t("sideBar.myAccount") }}
        </router-link>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href=""
          @click.prevent="signOut"
        >{{ $t("sideBar.signOut") }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import InitialsCircle from '../common/InitialsCircle'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'Sidebar',
  components: {
    InitialsCircle
  },
  mixins: [Helpers],
  props: ['user'],
  methods: {
    closeSideBar () {
      this.$parent.slideout.close()
    },
    signOut () {
      this.$store.dispatch('SIGN_OUT').then(() => {
        document.querySelector('html').classList.remove('slideout-open')
        this.$router.push('/security/login')
      })
    }
  }
}
</script>
