<template>
  <nav
    id="patient-nav-bar"
    class="navbar navbar-light bg-light"
  >
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <span class="navbar-text">{{ username }}</span>
    <router-link
      :to="{name: 'Home'}"
      class="navbar-brand"
    >
      <img
        class="logo-img"
        src="@/assets/images/owl-logo-white.svg"
        alt="Owl Logo"
        height="25"
      >
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  props: ['user'],
  computed: {
    username () {
      let username = ''
      if (this.user) {
        username = this.user.username ? this.user.username : this.user.firstName
      }
      return username
    }
  }
}
</script>
